.ant-collapse {
  letter-spacing: 0.6px;
  background-color: $table-in-modal-background;
  border-color: $table-border-color;

  .ant-collapse-content {
    background-color: $table-in-modal-background;
    border-color: $table-border-color;
  }

  .ant-collapse-item {
    .ant-collapse-header {
      border-bottom: 1px solid $table-border-color;
      border-color: $table-border-color;
      color: $color-white;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.ant-collapse > .ant-collapse-item {
  border: none;
}
