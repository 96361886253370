.ant-progress {
  .ant-progress-text {
    color: #fff;
  }
  .ant-progress-bg {
    background-color: $success-color;
  }

  .ant-progress-inner {
    background-color: $background-color-2;
  }
}
.ant-progress-status-success .ant-progress-bg {
  background-color: $success-color;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: $success-color;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path,
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: $success-color;
}
