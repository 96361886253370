/* *** Global Config *** */

/* Color Scheme */
$primary-green: #00c573;
$primary-dark-green: #04a260;
$color-white: #ffffff;

$background-color: #1d2228;
$background-color-2: #202f3b;
$background-color-3: #242f39;
$background-color-4: #141d27;
$background-color-dark: #121b22;

$border-color: #42515b;
$border-color-2: #37424a;

$text-light-color: #dfe3e5;
$text-light-color-2: #c6cccf;
$text-light-color-3: #848d93;
$text-light-color-4: #798d99;
$text-light-color-5: #5e6a70;
$text-light-color-6: #afb5b8;
$text-background-color: #2a353d;
$text-background-color-active: #37424a;

$clickable-color: #04a260;
$select-color: #00c573;
$info-color: #43a8e0;
$success-color: #40bea1;
$error-color: #da5671;
$error-color-hover: #ef6c86;
$error-color-active: #d9363e;
$warning-color: #cc9b4d;
$highlight-color: #22877f;

$test: rgb(40, 56, 67);

$background-gradient: linear-gradient(180deg, rgba(36, 57, 67, 1) 0%, rgba(40, 56, 67, 1) 100%);
$background-gradient-transparent: linear-gradient(180deg, rgba(36, 57, 67, 0.95) 0%, rgba(33, 43, 55, 0.95) 100%);
$background-gradient-transparent-vertical: linear-gradient(90deg, rgba(36, 57, 67, 0.95) 0%, rgba(33, 43, 55, 0.95) 100%);

/* Font Sizes + Line Height */
$font-size-base: 10px; // Proportionally changes all font sizes
$font-size-xl: $font-size-base * 3.4;
$font-size-lg: $font-size-base * 2.4;
$font-size-md: $font-size-base * 1.8;
$font-size-sm: $font-size-base * 1.6;
$font-size-xs: $font-size-base * 1.4;
$font-size-xxs: $font-size-base * 1.2;
$font-size-xxxs: $font-size-base;

/* Sizes (width / height) */
$size-base: 10px; // Proportionally changes all sizes (width / height)
$height-lg: $size-base * 8;

/* Paddings */
$padding-base: 10px; // Proportionally changes all paddings
$padding-xxl: $padding-base * 3.8;
$padding-xl: $padding-base * 2;
$padding-lg: $padding-base * 1.5;
$padding-md: $padding-base * 1.2;
$padding-sm: $padding-base * 0.8;
$padding-xs: $padding-base * 0.6;
$padding-xxs: $padding-base * 0.4;

$container-padding: $padding-xl;

/* Margins */
$margin-base: 10px; // Proportionally changes all margins
$margin-xxl: $margin-base * 6;
$margin-xl: $margin-base * 4;
$margin-lg: $margin-base * 3.2;
$margin-md: $margin-base * 2.4;
$margin-sm: $margin-base * 1.5;
$margin-xs: $margin-base * 1;
$margin-xxs: $margin-base * 0.6;

/*****************************************/

/* *** Components Config *** */

/* Avatar */
$avatar-background-color: $text-background-color;
$avatar-icon-color: $text-light-color-2;

/* Badge */
$badge-font-size: $font-size-xxxs;
$badge-height: $size-base * 1.6;
$badge-color: $error-color;

/* Breadcrumb */
$breadcrumb-color: $text-light-color-3;
$breadcrumb-last-color: $text-light-color-2;
$breadcrumb-last-background: $text-background-color;
$breadcrumb-font-size: $font-size-xs;

/* Button */
$button-color: $text-light-color;
$button-hover-color: $clickable-color;
$button-text-color: $background-color;

$button-height-lg: $size-base * 3.8;
$button-width-lg: $size-base * 20;
$button-padding-lg: $size-base * 4;
$button-font-size-lg: $font-size-xs;

$button-height-md: $size-base * 3.2;
$button-width-md: $size-base * 10;
$button-padding-md: $size-base * 2.5;
$button-font-size-md: $font-size-xxs;

$button-height-sm: $size-base * 3.2;
$button-width-sm: $size-base * 7.5;
$button-padding-sm: $size-base * 2.5;
$button-font-size-sm: $font-size-xxs;

/* Card */
$card-background: $background-color-3;
$card-background-gradient: $background-gradient-transparent;
$card-text-color: $text-light-color-2;
$card-border-color: $border-color;
$card-padding: $padding-xl;

/* Comment */
$comment-author-color: $text-light-color-3;
$comment-author-font-size: $font-size-xs;
$comment-date-color: $text-light-color-5;
$comment-date-font-size: $font-size-xxs;
$comment-content-color: $text-light-color-6;
$comment-content-font-size: $font-size-xs;
$comment-border-color: $background-color-3;

/* Divider */
$divider-line-color: $border-color;
$divider-text-color: $text-light-color-2;

/* Drag And Drop */
$drag-and-drop-background: rgba(0, 0, 0, 0.2);
$drag-and-drop-text-color: $text-light-color-2;

/* Dropdown */
$dropdown-background: $background-gradient-transparent;
$dropdown-active-background: rgba(0, 0, 0, 0.1);
$dropdown-border-color: transparentize($text-light-color-2, 0.5);
$dropdown-text-color: $text-light-color-2;
$dropdown-font-size: $font-size-xs;
$dropdown-icon-font-size: $font-size-sm;
$dropdown-item-padding: $padding-sm;

/* Form */
$form-label-color: $text-light-color-2;

/* Inputs (Number, Text, Text Area, Date Picker, etc.) */
$input-text-color: $text-light-color-2;
$input-border-color: $border-color;
$input-background: transparentize($background-color-dark, 0.7);

/* Header */
$header-background: #171c20;
$header-padding: 10px;
$header-height: $size-base * 8;
$logo-width: $size-base * 20;
$header-menu-font-size: $font-size-xxs;
$header-logo-width: 200px;
$header-height: 80px;
$header-height-mobile: 60px;

/* Footer */
$footer-background: $header-background;
$footer-height: $size-base * 6.4;
$footer-margin: 0;

/* Menu */
$menu-horizontal-background: transparent;
$menu-inline-background: transparent;
$menu-font-size: $font-size-xxs;
$menu-light-color: $text-light-color-2;
$menu-select-color: $select-color;

$submenu-background: $background-gradient-transparent;
$submenu-active-background: rgba(0, 0, 0, 0.1);
$submenu-color: $text-light-color-2;

/* Modal */
$modal-background: $background-gradient-transparent;
$modal-header-background: transparent;
$modal-border-color: $border-color;
$modal-title-color: $text-light-color-2;
$modal-text-color: $text-light-color-2;
$modal-title-font-size: $font-size-sm;
$modal-title-font-size-mobile: $font-size-md;
$modal-padding: $padding-xxl;
$modal-padding-mobile: $padding-xl;

/* Notification */
$notification-background: $background-gradient-transparent;
$notification-border-color: $border-color;
$notification-text-color: $text-light-color-2;
$notification-font-size: $font-size-sm;
$notification-min-width: $size-base * 25;
$notification-max-width: $size-base * 50;

/* Page Header (Layout Title e.g. DASHBOARD) */
$page-header-font-size-mobile: $font-size-lg;
$page-header-font-size: $font-size-xl;
$page-header-color: $text-light-color;
$page-header-border: transparentize($text-light-color, 0.75);
$page-sub-header-font-size: $font-size-md;
$page-sub-header-color: $text-light-color-3;

/* Select */
$select-text-color: $text-light-color-2;
$select-border-color: $border-color;
$select-background: transparentize($background-color-dark, 0.7);

$select-item-background: $text-background-color;
$select-no-data-color: $text-light-color-4;
$select-no-data-font-size: $font-size-xs;

/* Sidebar */
$sidebar-background: $header-background;

/* Steps */
$steps-text-color: $text-light-color-5;
$steps-active-text-color: $text-light-color-2;
$steps-line-background: $border-color-2;

/* Table */
$table-background: $background-color;
$table-border-color: lighten($text-background-color, 5);
$table-text-color: $text-light-color-2;
$table-head-text-color: $text-light-color-4;
$table-no-data-color: $text-light-color-4;
$table-no-data-font-size: $font-size-md;
$table-pagination-item-size: $size-base * 2.4;
$table-pagination-item-color: $text-light-color-4;
$table-pagination-item-background: $text-background-color;
$table-pagination-icon-color: $text-light-color-4;

$table-in-modal-background: rgba(0, 0, 0, 0.2);

/* Tabs */
$tabs-line-color: $border-color-2;
$tabs-text-color: $text-light-color-5;
$tabs-active-background-color: $text-background-color;
$tabs-active-text-color: $text-light-color;

/* Typography */
$typography-color: $text-light-color-2;
$typography-link-color: $clickable-color;
$typography-link-active-color: $select-color;

/* Screen Sizes */
$mobile-screen: 576px; // and less
$tablet-screen: 768px; // and more

$default-transition: 0.3s all;

/* Radius */
$border-radius-regular: 5px;

/* Text */
$letter-spacing-for-codes: 1.5px;
