.ant-tag {
  color: $select-text-color;
  background-color: $select-item-background;
  border-color: $select-border-color;
}

.ant-table-content,
.ant-drawer-content {
  .ant-tag {
    margin-bottom: $margin-xxs;
  }

  .anticon-close {
    color: $select-text-color;
  }
}

.ant-tag-close-icon {
  margin-left: $margin-xxs;
  color: $text-light-color-2;

  &:hover {
    color: $text-light-color-2;
  }
}
