.ant-select {
  &-selector {
    background-color: $select-background;
  }

  .ant-select-arrow {
    color: $select-text-color;
  }

  .ant-select-selection-placeholder,
  &::placeholder,
  input::placeholder {
    color: transparentize($select-text-color, 0.5);
  }
}

.ant-select-multiple .ant-select-selection-item {
  background-color: $select-item-background;
  border-color: $select-border-color;
  margin: 3px 2px;

  .anticon {
    color: $select-text-color;
  }
}

.ant-select-multiple.ant-select:not(.ant-select-customize-input),
.ant-select-multiple:not(.ant-select-customize-input),
.ant-select-single:not(.ant-select-customize-input) {
  &.ant-select-disabled .ant-select-selector,
  .ant-select-selector {
    color: $select-text-color;
    background-color: $select-background;
    border-color: $select-border-color;
  }

  &.ant-select-disabled .ant-select-selector {
    opacity: 0.5;
  }
}

.ant-select:not(.ant-select-disabled) {
  &.ant-select:hover,
  &.ant-select-focused,
  &.ant-select-open {
    .ant-select-selector {
      border-color: $clickable-color;
    }
  }
}

.ant-select-clear {
  background: $select-background;

  .anticon {
    color: $select-text-color;
  }
}

.ant-select-dropdown {
  background: $dropdown-background;
  border: 1px solid $dropdown-border-color;

  .ant-select-item {
    padding: $padding-sm $padding-lg;
    color: $select-text-color;
  }

  .ant-select-item-option {
    &-selected,
    &:hover {
      color: $select-color;
      background-color: $dropdown-active-background;
    }

    &-active {
      background-color: $dropdown-active-background;
    }
  }

  .ant-select-item-empty {
    color: $select-no-data-color;
    font-size: $select-no-data-font-size;
  }

  .ant-empty-normal {
    color: $select-no-data-color;
    font-size: $select-no-data-font-size;
    margin: $margin-xxl 0;
  }

  .ant-empty {
    .ant-empty-image {
      display: none;
    }
  }

  .ant-select-item-group {
    font-size: 12px;
  }

  .ant-select-item-option-grouped {
    padding-left: 30px;
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: $error-color !important;
}
