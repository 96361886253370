.ant-badge-count {
  font-size: $badge-font-size;
  height: $badge-height;
  min-width: $badge-height;
  border-radius: 4px;
  padding: 0 2px;
  background: $badge-color;
  border-bottom: none;
  box-shadow: none;
}
