.ant-table {
  color: $table-text-color;
  background-color: $table-background;
  text-align: center;
  letter-spacing: 0.6px;

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: $table-background;
  }

  .ant-table-thead > tr > th {
    background-color: $table-background;
    color: $table-head-text-color;
    border-bottom: 1px solid $table-border-color;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid $table-border-color;
  }

  tr.ant-table-row:hover > td {
    background-color: darken($table-background, 2);
  }

  tr.ant-table-expanded-row {
    background: $background-color-3;

    > td {
      background-color: #1d2228;
    }

    .ant-table {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
        0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

      padding: 5px;
      border-radius: 7px;
      background: #202f3b;
      margin: unset !important;

      .ant-table-thead > tr > th {
        background: transparent;
      }
    }
  }

  td.ant-table-column-sort {
    background: unset;
  }

  th.ant-table-column-has-sorters:hover {
    background: unset !important;
  }

  .ant-table-column-sorters {
    width: 100%;
    padding-right: 0px;
    align-items: unset;

    span:first-child {
      flex: 1;
    }

    .ant-table-column-sorter {
      color: unset;
    }
  }

  &.ant-table-bordered {
    .ant-table-container {
      border-top: 1px solid $table-border-color;
      border-left: 1px solid $table-border-color;

      .ant-table-content > table > thead > tr > th,
      .ant-table-content > table > tbody > tr > td,
      .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
      .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
      .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after {
        border-right: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
      }
    }
  }

  .ant-empty-normal {
    color: $table-no-data-color;
    font-size: $table-no-data-font-size;
    margin: $margin-xxl 0;
  }

  .ant-empty-image {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-placeholder {
    & > td > .ant-table-expanded-row-fixed {
      width: auto !important;
    }

    &:hover > td {
      background: $table-background;
    }
  }

  &-wrapper {
    .ant-spin-container::after {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .ant-table-filter-trigger {
    .anticon {
      color: $table-head-text-color;
    }

    &.active {
      .anticon {
        color: $select-color !important;
      }
    }
  }

  .ant-table-filter-trigger-container {
    &-open,
    &:hover {
      background-color: transparent;

      .anticon {
        color: $select-color;
      }
    }
  }

  .ant-table-filter-column {
    &:hover {
      .ant-table-filter-trigger-container {
        background-color: transparent !important;
      }
    }
  }
}

.ant-table-filter-dropdown {
  .ant-table-filter-dropdown-btns > .ant-btn-link {
    border: none;
  }
}

.disable-table-row-hover {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: unset !important;
  }
}

.ant-pagination {
  .ant-pagination-item {
    border-radius: 4px;
    border: none;
    padding: 1px 4px 0 4px;
    min-width: $table-pagination-item-size;
    height: $table-pagination-item-size + 2px;
    font-weight: 600;
    line-height: $table-pagination-item-size;
    background-color: transparent;

    a {
      color: $table-pagination-item-color;
    }

    &-active,
    &:hover {
      background-color: $table-pagination-item-background;

      a {
        color: $select-color;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: transparent;
      border: none;
      color: $table-pagination-icon-color;
      font-size: $font-size-md;
    }

    &:not(.ant-pagination-disabled) {
      .ant-pagination-item-link:hover {
        cursor: pointer;
        color: $select-color;
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: $select-color;
    }

    .ant-pagination-item-ellipsis {
      color: $table-pagination-icon-color;
    }
  }
}

.ant-modal-body {
  .ant-table {
    background: $table-in-modal-background;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: $table-in-modal-background;
    }

    .ant-table-thead > tr > th {
      background: transparent;
    }

    tr.ant-table-row:hover > td {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
