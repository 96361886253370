.ant-divider-horizontal {
  border-color: transparentize($divider-line-color, .3);

  &.ant-divider-with-text {
    color: $divider-text-color;
    &:after,
    &:before {
      border-color: $divider-line-color;
    }
  }
}