.ant-list {
  letter-spacing: 0.6px;

  &-split .ant-list-header,
  .ant-list-footer {
    border-color: $table-border-color;
    color: $table-head-text-color;
    font-weight: 500;
  }

  .ant-list-footer {
    border-top: 1px solid $table-border-color;
  }

  .ant-list-item {
    color: $text-light-color-2;
    border-color: $table-border-color;
  }

  .ant-list-item-meta-title {
    color: $table-text-color;
  }

  .ant-empty-normal {
    color: $table-no-data-color;
    font-size: $table-no-data-font-size;
    margin: $margin-xxl 0;
  }

  .ant-empty-image {
    display: none;
  }
}

.ant-list-bordered {
  background-color: #242f39;
  border: 1px solid $table-border-color;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border: none;
}
