.ant-tabs {
  .ant-tabs-nav::before {
    border-bottom: 2px solid $tabs-line-color;
  }

  .ant-tabs-tab {
    padding: $padding-md+2 $margin-lg $padding-md $margin-lg;
    text-transform: uppercase;
    margin: 0;
    color: $tabs-text-color;
    z-index: 1;

    .ant-tabs-tab-btn {
      &:focus,
      &:hover {
        color: $tabs-text-color;
      }
    }

    &:hover .ant-tabs-tab-btn {
      color: $tabs-active-text-color;
    }

    &-active, &-active {
      .ant-tabs-tab-btn, .ant-tabs-tab-btn:focus {
        font-weight: 600;
        color: $tabs-active-text-color;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: $tabs-active-background-color;
    z-index: 0;
    height: 100% !important;
    bottom: 2px;
  }
}