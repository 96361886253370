.ant-btn {
  height: $button-height-md;
  min-width: $button-width-md;
  font-size: $button-font-size-md;
  font-weight: 600;
  padding: 3px $button-padding-md 1px $button-padding-md;
  text-transform: uppercase;
  border-width: 2px;
  color: $button-color;
  background-color: transparent;
  border-color: $button-color;
  letter-spacing: 0.4px;

  &:focus {
    color: $button-color;
    background-color: transparent;
    border-color: $button-color;
  }

  &:hover {
    color: $button-hover-color;
    background-color: transparent;
    border-color: $button-hover-color;
  }

  &-primary,
  &-primary:focus {
    color: $button-text-color;
    background-color: $button-color;
    border-color: $button-color;

    &:hover {
      color: $button-color;
      background-color: $button-hover-color;
      border-color: $button-hover-color;
    }
  }

  &-dangerous,
  &-dangerous:focus {
    color: $error-color;
    background-color: transparent;
    border-color: $error-color;

    &:hover {
      color: darken($error-color, 10);
      border-color: darken($error-color, 10);
    }

    &.ant-btn-primary {
      color: $button-color;
      background-color: $error-color;
      border-color: $error-color;

      &:hover,
      &:focus {
        background-color: darken($error-color, 10);
        border-color: darken($error-color, 10);
      }
    }
  }
  &-text,
  &-text:focus {
    border: none !important;

    &[disabled] {
      color: $text-light-color;
    }

    &[disabled]:hover {
      color: $text-light-color;
    }
  }

  .ant-btn-loading-icon {
    margin-left: math.div(-$button-padding-md, 1.5);
  }

  &-sm {
    height: $button-height-sm;
    min-width: $button-width-sm;
    font-size: $button-font-size-sm;

    .ant-btn-loading-icon {
      margin-left: math.div(-$button-padding-sm, 1.5);
    }
  }

  &-lg {
    height: $button-height-lg;
    min-width: $button-width-lg;
    font-size: $button-font-size-lg;

    .ant-btn-loading-icon {
      margin-left: math.div(-$button-padding-lg, 1.5);
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  &-link[disabled]:hover,
  &-link[disabled] {
    border-color: $button-color;
    color: $button-color;
  }
}

.ant-btn:focus > .ant-btn-loading-icon,
.ant-btn:active > .ant-btn-loading-icon,
.ant-btn > .ant-btn-loading-icon {
  position: absolute;
}
